import jwt_decode from 'jwt-decode';
import './admin.css';
import {useEffect, useRef, useState} from 'react';
import {getUsers, getPermissions, getUserData} from '../../api';
import {getAllValues, UI} from '../../utils';
import {DataGrid} from "@mui/x-data-grid";
import {TextField} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {useTranslation} from "react-i18next";
import {atom_logo} from './assets'
import {loader} from "../home/assets";
import dayjs from "dayjs";
import {Button} from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import AdminModal from "./modal/AdminModal";
import ManageDomainsModal from "./modal/ManageDomainsModal";

require('dayjs/locale/de')
require('dayjs/locale/es')

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc)
dayjs.extend(timezone)

const Admin = () => {
    const [searchParam, setSearchParam] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [users, setUsers] = useState([]);
    const [checkedUsersId, setCheckedUsersId] = useState([]);
    const [isAdmin, setIsAdmin] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [userData, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalState, setModalState] = useState('');
    const [userList, setUserList] = useState([]);
    const [modifyState, setModifyState] = useState('Modify');
    const [manageOpen, setManageOpen] = useState(false)
    const {t, i18n} = useTranslation();

    const columns = [
        {field: 'col1', headerName: t('username_column'), minWidth: 250, flex: 1},
        {field: 'col2', headerName: t('firstname_column'), minWidth: 150, flex: 1},
        {field: 'col3', headerName: t('lastname_column'), minWidth: 150, flex: 1},
        {field: 'col4', headerName: t('permissions_column'), minWidth: 665, flex: 4},
        {field: 'col5', headerName: t('creation_date_column'), minWidth: 250, flex: 1},
    ];

    const subscription = useRef(true);

    useEffect(() => {
        const decodedToken = jwt_decode(localStorage.getItem('token'))
        if (decodedToken.admin) {
            setIsAdmin(true);
        } else {
            window.location = UI
        }

        async function getUserPreferences() {
            const user = await getUserData(localStorage.getItem('token'))
            setUser(user.data);
            i18n.changeLanguage(user.data.language)
            return user.data
        }

        getPermissions(localStorage.getItem('token')).then(r => {
            let perms = getAllValues(r.data.permissions.atom)

            setPermissions(perms.map(e => {
                return {checked: false, perm: e}
            }))
        })
        getUserPreferences().then(res => {
            getUsers(localStorage.getItem('token'))
                .then(r => setTableUsers(r.data.users, setUsers, res))
        })
        setIsLoading(false)
        return () => subscription.current = false;
    }, [])
    const setTableUsers = (users, setUsers, data = userData) => {
        const timeZoneMap = {
            'utc5': 'Etc/GMT-5',
            'utc6': 'Etc/GMT-6',
            'utc7': 'Etc/GMT-7',
            'utc2': 'Etc/GMT+2',
            'utc53': 'Asia/Kolkata',
            'utc0': 'UTC'
        };
        const testGetUsers = () => {
            return users.map((user, index) => {
                return {
                    id: index + 1,
                    col1: user.username,
                    col2: user.first_name,
                    col3: user.last_name,
                    col4: user.permissions.join(', '),
                    col5: user.creation_time ? dayjs(user.creation_time).tz(timeZoneMap[data?.timezone]).locale(i18n.language).format(data?.date_format === 'mm' ? 'dddd, MM/DD/YY' : 'dddd, DD/MM/YY') : ''
                }
            })
        }
        setUsers(testGetUsers)
    }

    return isLoading ?
        (
            <div style={{position: 'absolute', top: '40vh', left: 'calc((100vw - 17.44vh) / 2)'}}>
                <img className='loader' src={loader}/>
            </div>
        ) : (
            isAdmin && (
                <>
                    <AdminModal users={users} userList={userList} t={t} setSearchParam={setSearchParam}
                                searchParam={searchParam} setUserList={setUserList} checkedUsersId={checkedUsersId}
                                setCheckedUsersId={setCheckedUsersId} modalState={modalState} modifyState={modifyState}
                                setModifyState={setModifyState} permissions={permissions} setMessage={setMessage}
                                setOpen={setOpen} setSeverity={setSeverity} setTableUsers={setTableUsers}
                                setUsers={setUsers} setModalOpen={setModalOpen} modalOpen={modalOpen}/>
                    <ManageDomainsModal modalOpen={manageOpen} setModalOpen={setManageOpen} setMessage={setMessage} setOpen={setOpen} setSeverity={setSeverity}/>
                    <div className='admin-panel'>
                        <Snackbar
                            open={open}
                            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                            autoHideDuration={6000}
                            onClose={e => setOpen(false)}
                        >
                            <Alert severity={severity}>{message}</Alert>
                        </Snackbar>
                        <img className='admin-atom-logo' onClick={() => window.location = UI} src={atom_logo}/>
                        <div className='admin-ui-header'>
                            <div className='table-search'>
                                <TextField className='table-search-input' variant='standard'
                                           placeholder={t("search_by_name")}
                                           onChange={e => setSearchParam(e.target.value)}/>
                                <SearchIcon className='table-search-icon'/>
                            </div>
                            {checkedUsersId.length > 0 ?
                                <div className='header-buttons'>
                                    <Button
                                        variant='outlined'
                                        color='warning'
                                        endIcon={<RemoveIcon/>}
                                        onClick={_ => {
                                            setModalOpen(true)
                                            setModalState('Remove')
                                        }}>
                                        {t('remove_selected_users')}
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        color='warning'
                                        endIcon={<ModeEditIcon/>}
                                        onClick={_ => {
                                            setModalOpen(true)
                                            setModalState('Modify')
                                        }}>
                                        {t('modify_permissions')}
                                    </Button>
                                </div>
                                :
                                <Button
                                    variant='outlined'
                                    color='warning'
                                    endIcon={<AddIcon/>}
                                    onClick={_ => {
                                        setModalOpen(true)
                                        setModalState('Add')
                                    }}>
                                    {t('add_new_users')}
                                </Button>
                            }
                            <Button
                                variant='outlined'
                                color='warning'
                                onClick={_ => setManageOpen(true)}
                            sx={{marginLeft:'auto', display: 'flex'}}>Manage Domains</Button>
                        </div>

                        <div className='table-container'>
                            <DataGrid
                                getRowHeight={() => 'auto'}
                                rows={
                                    users.filter((e) => e.col1?.toLowerCase().includes(searchParam.toLowerCase()) ||
                                        e.col2?.toLowerCase().includes(searchParam.toLowerCase()) ||
                                        e.col3?.toLowerCase().includes(searchParam.toLowerCase()))
                                }
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : null
                                }
                                columns={columns}
                                checkboxSelection
                                onRowSelectionModelChange={(ids) => {
                                    setCheckedUsersId(ids)
                                }}
                                rowSelectionModel={checkedUsersId}
                            />
                        </div>
                    </div>
                </>
            )
        )
};

export default Admin;