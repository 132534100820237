import {useCallback, useRef, useState} from "react";
import {useDebounceEffect} from "../../../utils";
import {useDropzone} from "react-dropzone";
import {updatePreferences, uploadImage} from "../../../api";
import {Modal} from "@mui/material";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import './avatarProcessing.css'
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export const AvatarProcess = ({
                                  setUserAvatar,
                                  setAvatarProcessing,
                                  avatarProcessing,
                                  setMessage,
                                  setSeverity,
                                  setOpen
                              }) => {
    const [src, setSrc] = useState(null);
    const [output, setOutput] = useState(null);

    const [completedCrop, setCompletedCrop] = useState();
    const [crop, setCrop] = useState();

    const imgRef = useRef(null);

    useDebounceEffect(async () => {
        if (completedCrop) {
            cropImageNow()
        }
    }, 100, [completedCrop])
    const onImageLoad = (e) => {
        setCrop({
            unit: 'px',
            width: 165,
            height: 165,
            x: (e.currentTarget.width - 165) / 2,
            y: 82
        })
        setCompletedCrop({
            unit: 'px',
            width: 165,
            height: 165,
            x: (e.currentTarget.width - 165) / 2,
            y: 82
        })
    }
    const cropImageNow = () => {
        const canvas = document.createElement('canvas');
        canvas.width = imgRef.current.naturalWidth;
        canvas.height = imgRef.current.naturalHeight;
        const ctx = canvas.getContext('2d')

        const coefficient = imgRef.current.naturalHeight / imgRef.current.height;

        ctx.save()

        ctx.drawImage(
            imgRef.current,
            Math.floor(completedCrop.x * coefficient),
            Math.floor(completedCrop.y * coefficient),
            Math.floor(completedCrop.width * coefficient),
            Math.floor(completedCrop.height * coefficient),
            0,
            0,
            canvas.width,
            canvas.height
        )

        ctx.restore()

        const base64Image = canvas.toDataURL('image/png');
        setOutput(base64Image);
    };

    const onDrop = useCallback(acceptedFiles => {
        setSrc(URL.createObjectURL(acceptedFiles[0]))
    }, [])

    const {getRootProps, getInputProps} = useDropzone({
        onDrop, accept: {
            'image/png': ['.png', '.jpeg', '.jpg']
        }
    })

    const onAcceptImg = () => {
        setUserAvatar(output)
        setAvatarProcessing(null)

        uploadImage(localStorage.getItem("token"), output).then(res => {
            updatePreferences(localStorage.getItem('token'), {image_link: res.data.image_url}).then(_ => {
            }).catch(e => {
                setMessage(e.message)
                setSeverity('error')
                setOpen(true)
            })
        })
    }

    const onDeleteImg = () => {
        setUserAvatar(null);
        setAvatarProcessing(null);
        updatePreferences(localStorage.getItem('token'), {image_link: ''}).then(r => console.log(r))
    }

    const onCancel = () => {
        setAvatarProcessing(null);
    }

    return (
        <Modal open={avatarProcessing} onClose={onCancel}>
            <div className='avatar-process-container'>
                <div style={{
                    display:"flex", padding: "0 10px", alignItems: "flex-end", justifyContent: "space-between"
                }}>
                    <span>Upload Photo</span>
                    <IconButton onClick={onCancel} className='avatar-close-button'>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <hr/>
                <p style={{marginLeft: '20px'}}>Upload .png or .jpg file</p>
                <div className='avatar-img'>
                    {src ? <div className='crop-img-container'>
                            <ReactCrop
                                src={src}
                                crop={crop}
                                onChange={(a, c) => {
                                    setCrop(c)
                                }}
                                onComplete={(c) => {
                                    setCompletedCrop(c)
                                }}
                                aspect={1}
                                circularCrop={true}
                            >
                                <img style={{height: '330px'}} ref={imgRef} src={src} alt={"img"} onLoad={onImageLoad}/>
                            </ReactCrop>
                        </div> :
                        <div className='dropzone-area' {...getRootProps()}> Drag your file or select file
                            <input className='dropzone-area' {...getInputProps()} />
                        </div>}
                </div>
                <hr/>

                {src ? <button className='avatar-delete-btn' {...getRootProps()}>Upload New Photo</button> :
                    <button className='avatar-delete-btn' onClick={onDeleteImg}>Delete Current Photo</button>}
                <button disabled={!src} onClick={onAcceptImg} className='avatar-bottom-btn '>Save</button>
            </div>
        </Modal>

    )
}