import {updatePreferences} from "../../../api";
import CheckIcon from "@mui/icons-material/Check";

const Language = ({i18n, setMessage, setSeverity, setOpen, t}) => {
    const languages = {
        en: 'English',
        de: 'Deutsch',
        es: 'Español'
    }

    const setLanguage = (language) => {
        const data = {language}
        const token = localStorage.getItem('token');


        updatePreferences(token, data)
            .then(_ => {
                i18n.changeLanguage(language).then(_ => {
                });

            })
            .catch(err => {
                setMessage(err.message)
                setSeverity('error')
                setOpen(true)
            })
    }

    return (
        <>
            <p className='top-text'>{t('select_lang')}</p>
            <div className='language-input-container'>
                <ul className='li-container'>
                    {
                        Object.keys(languages).map(btn => {
                            return (
                                <li className='user-settings-elem language-elem'
                                    onClick={_ => setLanguage(btn)}>
                                    <p className='language-label'>{languages[btn]} </p>
                                    {(btn === t('lang')) && <span className='check-icon'>
                                            <CheckIcon fontSize='large' sx={{color: 'rgba(239, 125, 11, 1)'}}/>
                                        </span>}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    )
}

export default Language