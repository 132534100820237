import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import Auth from "./components/auth/Auth";
import Home from "./components/home/Home";
import Admin from "./components/admin/Admin";


function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/auth" element={<Auth isAuthorized={false}/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="/admin" element={<Admin/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
