export function processToken() {
    let url = window.location.href.split("token=")[1]?.split('&');
    let pattern = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+=#]*$/;

    if (url && pattern.test(url[0])) {
        let token = url[0]

        token = token[token.length -1] === "#" ? token.slice(0, -1) : token

        localStorage.setItem("token", token);

        return token;
    }
    return null;
}

