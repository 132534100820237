import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

const UserProfile = ({userAvatar, setAvatarProcessing, avatarProcessing, user}) => {
        return (
            <>
                <div className='user-settings-img'>
                    {
                        userAvatar ? <img src={userAvatar} className='user-image' alt=""/> :
                            <PersonOutlineOutlinedIcon sx={{fontSize: 90}} className='user-image'/>
                    }


                    <div className='user-edit'>
                        <ModeEditOutlinedIcon sx={{fontSize: 85}} className='user-edit-img'
                         onClick={() => setAvatarProcessing(!avatarProcessing)}/>
                    </div>

                </div>
                <div className='user-settings-title-container'>
                    <p className='user-settings-title'>{user.first_name} {user.last_name}</p>
                    <p className='user-settings-title settings-user-name'>{user.username}</p>
                </div>
            </>
        )
    }

export default UserProfile