import {UI} from "./urls";
import {useEffect} from "react";

export function redirectToAuth() {
    window.location.replace(`${UI}auth`);
}

export function isRedirect() {
    let url = (window.location.href.split('redirect_url=')[1]?.split('&'));

    if (url) {
        const domain = url[0].split('.').slice(-2).join('.');

        if (domain === 'fissionsw.com' || domain === 'fissionsw.com/') {
            return url[0];
        }
    }
    return null;
}

export function checkVersion(url) {
    return url.includes('dev') ? '-dev' : url.includes('stg') ? '-stg' : '';
}

export const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, {type: mime})
}

export const useDebounceEffect = (fn, waitTime, deps) => {
    useEffect(() => {
        const t = setTimeout(() => {
            fn.apply(undefined, deps)
        }, waitTime)

        return () => {
            clearTimeout(t)
        }
    }, deps)
}

export const getAllValues = (obj) => {
    const values = [];
    Object.values(obj).forEach(value => {
        if (typeof value === 'object') {
            values.push(...getAllValues(value));
        } else {
            values.push(value);
        }
    })
    return values;
}
