import axios from "axios";
import {
    GET_USER,
    GET_USERS,
    GET_USER_DATA,
    GET_PERMISSIONS,
    ATOM_ADMIN_USER,
    USER_PREFERENCES, USER_IMAGE, REPORT_ISSUE
} from "../utils";

import {dataURLtoFile} from "../utils";
import {DOMAIN_USERS, DOMAINS} from "../utils/urls";
import config from "bootstrap/js/src/util/config";

export const getUsers = async (token) => {
    return axios.get(GET_USERS, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getUser = async (token) => {

    return axios.get(GET_USER, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getUserData = async (token) => {
    return axios.get(GET_USER_DATA, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getPermissions = async (token) => {

    return axios.get(GET_PERMISSIONS, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const addUser = async (token, username, perms) => {
    return axios.post(ATOM_ADMIN_USER + username, {permissions: perms}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const addUsers = async (token, users, perms) => {
    return axios.post(GET_USERS, {
        users: users.map(el => {
            return {username: el, permissions: perms}
        })
    }, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const deleteUsers = async (token, users) => {
    return axios.delete(GET_USERS, {
        headers: {
            "Authorization": `Bearer ${token}`
        }, data: {
            users: users
        }
    })
}

export const modifyUsers = async (token, users, perms) => {
    return axios.put(GET_USERS, {
        users: users.map(e => {
            return {username: e, permissions: perms}
        })
    }, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const modifyPermissions = async (token, users) => {
    return axios.put(GET_USERS, {users}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const updatePreferences = async (token, preferences) => {
    return axios.put(USER_PREFERENCES, preferences, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const uploadImage = async (token, image) => {
    const formData = new FormData();
    const file = dataURLtoFile(image)
    formData.append('file', file)
    return axios.post(USER_IMAGE, formData, {
        headers: {
            "content-type": "multipart/form-data",
            "Authorization": `Bearer ${token}`
        }
    })
}

export const reportIssue = async (token, message) => {
    return axios.post(REPORT_ISSUE, {text: message}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getDomains = async (token) => {
    return axios.get(DOMAINS, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const putDomains = async (token, domains) => {
    return axios.put(DOMAINS, {domains: domains}, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}

export const getDomainUsers = async (token, domains = null) => {
    return axios.post(DOMAIN_USERS, domains, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
}