import {useState} from "react";
import {updatePreferences} from "../../../api";
import {
    Autocomplete,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField
} from "@mui/material";

const AccountPreferences = ({user, setMessage, setOpen, setSeverity, t}) => {
    const [time, setTime] = useState(user.timezone);
    const [dateFormat, setDateFormat] = useState(user.date_format ? user.date_format : 'mm');
    const [timeFormat, setTimeFormat] = useState(user.time_format ? user.time_format : '12hour');

    const handleTimezoneChange = (value) => {
        setTime(value);
        const data = {
            timezone: value
        }
        setPreferences(data)
    };

    const handleDateFormatChange = (event) => {
        setDateFormat(event.target.value);

        const data = {
            date_format: event.target.value
        }
        setPreferences(data)
    }

    const handleTimeFormatChange = (event) => {
        setTimeFormat(event.target.value);

        const data = {
            time_format: event.target.value,
        }
        setPreferences(data)

    }

    const setPreferences = (data) => {
        const token = localStorage.getItem('token');

        updatePreferences(token, data)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                setMessage(err.message)
                setSeverity('error')
                setOpen(true)
            })

    }

    return (
        <div>
            <div className='acc-pref-time-zone-container'>
                <p className='time-zone-label'>{t('timezone')}</p>
                <Autocomplete renderInput={(params) => <TextField {...params} variant={'standard'}/>}
                              options={Intl.supportedValuesOf('timeZone')} onChange={(_, v) => handleTimezoneChange(v)}
                              value={time || null} disableClearable fullWidth/>
            </div>

            <FormControl style={{gap: '1.5rem', width: '100%'}}>
                <div className='language-format-block'>
                    <p className='format'>{t('timeformat')}</p>
                    <RadioGroup
                        style={{width: '73%'}}
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        row={true}
                        value={timeFormat}
                        onChange={handleTimeFormatChange}
                    >
                        <FormControlLabel style={{width: '48%'}} value="h12" control={<Radio/>} label={t('h12')}/>
                        <FormControlLabel value="h24" control={<Radio/>} label={t('h24')}/>
                    </RadioGroup>
                </div>

                <div className='language-format-block'>
                    <p className='format'>{t('dateformat')}</p>
                    <RadioGroup
                        style={{width: '73%', flexWrap: 'nowrap'}}
                        aria-labelledby="radio-buttons -group-label"
                        name="radio-buttons-group"
                        row={true}
                        value={dateFormat}
                        onChange={handleDateFormatChange}
                    >
                        <FormControlLabel style={{width: '48%'}} value="mm" control={<Radio/>} label={t('mm')}/>
                        <FormControlLabel value="dd" control={<Radio/>} label={t('dd')}/>
                    </RadioGroup>
                </div>

                <div>
                    <p>{t('member')} <span
                        style={{color: '#636363'}}>{new Date(user.creation_time).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    })}</span></p>
                </div>

                <div>
                    <p>{t('request')}</p>
                    <div className=''>
                        <TextField className='table-search-input' variant='standard'
                                   style={{backgroundColor: 'rgb(242,242,242)'}}
                                   placeholder={t('enter_email')}/>
                    </div>
                    <button
                        className='user-settings-btn'
                        onClick={setPreferences}
                        style={{marginTop: '10px', width: '60%'}}>{t('request_btn')}
                    </button>
                </div>
            </FormControl>
        </div>
    )
}

export default AccountPreferences