import {useState} from "react";
import {TextField} from "@mui/material";
import ModifyAddPermissions from "./ModifyAdd";
import './Modal.css'
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


const AddUsersModal = ({t, userList, setUserList, users, searchParam, setSearchParam, permissions}) => {
        const [username, setUsername] = useState('');
        const [inputInvalid, setInputInvalid] = useState(false)
        const [invalidMessage, setInvalidMessage] = useState();

        return (
            <div>
                <div className='form-header'>
                    <p className='form-p'>{t("users_being_added")}:</p>
                    <ul className='form-header-user-list'>
                        {userList.map((el) => {
                            return (<li className='li-users-list'>
                                {el}
                                <span onClick={_ => setUserList(userList.filter(e => e !== el))}
                                      style={{fontSize: '38px', cursor: 'pointer'}}><RemoveIcon/></span>
                            </li>)
                        })}
                    </ul>

                    <TextField error={inputInvalid}
                               variant='standard'
                               helperText={inputInvalid ? invalidMessage : ''}
                               className='modal-search'
                               placeholder={t("enter_email")}
                               onChange={event => {
                                   setUsername(event.target.value)
                               }}/>
                    <span style={{fontSize: '38px', cursor: 'pointer'}} onClick={() => {
                        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username)) {
                            setInputInvalid(true)
                            setInvalidMessage("Invalid user email")
                        } else if (userList.includes(username) || users.map(e => e.col1).includes(username)) {
                            setInputInvalid(true)
                            setInvalidMessage("Duplicate user email")
                        } else {
                            setInputInvalid(false)
                            setUserList([...userList, username])
                        }
                    }}><AddIcon/></span>
                </div>
                <div className='form-permissions'>
                    <div>{t("select_permissions")}</div>
                    <div className='form-input-btn-section'>
                        <input value={searchParam} onChange={(e) => setSearchParam(e.target.value)}
                               className='form-search' type='text'
                               placeholder={t("search")}/>
                        <button onClick={_ => setSearchParam('')} className='form-cancel-btn'>{t("cancel")}</button>
                    </div>

                    <hr/>

                    <div className='form-permissions-list'>
                        <ModifyAddPermissions permissions={permissions} searchParam={searchParam}/>
                    </div>
                </div>
            </div>
        )

    }

export default AddUsersModal