import Login from "./Login";
import "./auth.css";
import {
    atom_logo,
    background,
    background1,
    background2,
    background3,
    background4,
    background5,
    background6,
    background7
} from './assets'

const AuthPage = () => {
    return (
        <div style={{minHeight: '910px', position: "absolute", width: '100%'}}>
            <img className="backgroundImg firstImg" src={background} alt="background"/>
            <img className="backgroundImg secondImg" src={background1} alt="background 1"/>
            <img className="backgroundImg thirdImg" src={background2} alt="background 2"/>
            <img className="backgroundImg fourthImg" src={background3} alt="background 3"/>
            <img className="backgroundImg fifthImg" src={background4} alt="background 4"/>
            <img className="backgroundImg sixthImg" src={background5} alt="background 5"/>
            <img className="backgroundImg seventhImg" src={background6} alt="background 6"/>
            <img className="backgroundImg eighthImg" src={background7} alt="background 7"/>


            <div className="auth-page">
                <img className="atom_logo" src={atom_logo} alt="atom logo"/>
                <Login/>
            </div>
        </div>

    )
}

export default AuthPage;
