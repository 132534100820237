import Alert from "@mui/material/Alert";
import './Modal.css'
const DeleteUserForm = ({users, checkedUsersId, t}) => {
        return (
            <div className='delete-form'>
                <Alert severity='error'>{t("delete_warning")}</Alert>
                <p className='form-p'>{t("users_deleted")}:</p>
                <ul className='form-header-user-list'>
                    {users.filter(user => checkedUsersId.includes(user.id)).map(e => <li>{e.col1}</li>)}
                </ul>
            </div>
        )
    }

export default DeleteUserForm;