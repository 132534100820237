import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Button, Modal} from "@mui/material";
import {addUsers, deleteUsers, getUsers, modifyPermissions} from "../../../api";
import AddUsersModal from "./AddUserModal";
import DeleteUserForm from "./DeleteModal";
import ModifyUserModal from "./ModifyUserModal";
import './Modal.css'

const AdminModal = ({
                        t,
                        modalState,
                        modifyState,
                        setModifyState,
                        permissions,
                        userList,
                        setTableUsers,
                        setUsers,
                        setOpen,
                        setMessage,
                        setSeverity,
                        setUserList,
                        users, checkedUsersId, setCheckedUsersId, searchParam, setSearchParam, modalOpen, setModalOpen
                    }) => {
    return (
        <Modal open={modalOpen} onClose={_ => {
            setModalOpen(false)
            setModifyState('Modify')
        }}>
            <div className="modal-container">
                <header>
                    {modalState === 'Add' && t('add_users')}
                    {modalState === 'Remove' && t('remove_selected_users')}
                    {modalState === 'Modify' && t('modify_permissions')}
                    <IconButton onClick={_ => {
                        setModalOpen(false)
                        setModifyState('Modify')
                    }}>
                        <CloseIcon/>
                    </IconButton>
                </header>
                {modalState === 'Add' &&
                    <AddUsersModal users={users} t={t} userList={userList} setUserList={setUserList}
                                   searchParam={searchParam} setSearchParam={setSearchParam} permissions={permissions}/>}
                {modalState === 'Remove' && <DeleteUserForm users={users} checkedUsersId={checkedUsersId} t={t}/>}
                {modalState === 'Modify' && <ModifyUserModal users={users} checkedUsersId={checkedUsersId}
                                                             permissions={permissions} setModifyState={setModifyState}
                                                             modifyState={modifyState} t={t} />}
                <footer>
                    {modalState === 'Add' && <Button
                        variant='outlined'
                        color='warning'
                        onClick={async () => {
                            const perms = permissions.filter(e => e.checked).map(e => e.perm)
                            addUsers(localStorage.getItem('token'), userList, perms)
                                .then(async _ => {
                                    await getUsers(localStorage.getItem('token')).then(r => {
                                        setTableUsers(r.data.users, setUsers)
                                    })
                                    setOpen(true)
                                    setMessage(t('messages.success.user_added'))
                                    setSeverity('success')
                                })
                                .catch(r => {
                                    setOpen(true)
                                    setMessage(r.message)
                                    setSeverity('error')
                                })
                            setModalOpen(false)
                            setUserList([])
                        }}>{t('add_users')}</Button>}
                    {modalState === 'Remove' && <Button
                        variant='outlined'
                        color='warning'
                        onClick={_ => {
                            let userList = users.filter(user => checkedUsersId.includes(user.id)).map(e => {
                                return {username: e.col1}
                            })

                            deleteUsers(localStorage.getItem('token'), userList).then(async _ => {
                                await getUsers(localStorage.getItem('token')).then(r => {
                                    setTableUsers(r.data.users, setUsers)
                                })
                                setOpen(true)
                                setMessage(t('messages.success.user_deleted'))
                                setSeverity('success')
                            }).catch(err => {
                                setOpen(true)
                                setMessage(err.message)
                                setSeverity('error')
                            })
                            setCheckedUsersId([])
                            setModalOpen(false)
                        }}>{t('delete')}</Button>}
                    {modalState === 'Modify' && <Button
                        variant='outlined'
                        color='warning'
                        onClick={_ => {
                            const perms = modifyState === 'Remove' ?
                                permissions.filter(e => !e.checked && !e.defaultChecked).map(e => e.perm)
                                :
                                permissions.filter(e => e.checked || e.defaultChecked).map(e => e.perm)
                            const userList = modifyState === 'Modify' ?
                                users.filter(user => checkedUsersId.includes(user.id)).map(e => {
                                    return {
                                        username: e.col1,
                                        permissions: perms
                                    }
                                }) :
                                modifyState === 'Add' ?
                                    users.filter(user => checkedUsersId.includes(user.id)).map(e => {
                                        return {
                                            username: e.col1,
                                            permissions: [...new Set([...e.col4.split(', '), ...perms])].filter(el => el !== '')
                                        }
                                    }) :
                                    users.filter(user => checkedUsersId.includes(user.id)).map(e => {
                                        return {
                                            username: e.col1,
                                            permissions: e.col4.split(', ').filter(el => !perms.includes(el) && el !== '')
                                        }
                                    })

                            modifyPermissions(localStorage.getItem('token'), userList)
                                .then(async _res => {
                                    await getUsers(localStorage.getItem('token')).then(r => {
                                        setTableUsers(r.data.users, setUsers)
                                    })
                                    setOpen(true)
                                    setMessage(t('messages.success.user_modified'))
                                    setSeverity('success')
                                })
                                .catch(err => {
                                    setOpen(true)
                                    setMessage(err.message)
                                    setSeverity('error')
                                })
                            setCheckedUsersId([])
                            setModalOpen(false)
                            setModifyState('Modify')
                        }}>{t('Modify')}</Button>}
                </footer>
            </div>
        </Modal>
    )
}

export default AdminModal