import {
    prometheus,
    jupyter,
    jupyter_secure,
    git,
    ceph,
    google,
    admin_ui,
    grafana,
    atom_library,
    management,
    fast_api_docs,
    data_harmonization,
    read_the_docs,
    smart_mapping
} from "../assets";

import {
    JUPYTER_HUB, API, JUPYTER_HUB_SECURE, PROMETHEUS, ATOM_LIB_DOCUMENTATION, ATOM_DOCUMENTATION,
    GRAFANA, GITHUB, SMT, CEPH, GCP, ADMIN_UI, MANAGEMENT, HARMONIZATION_API, ATOM_API_DOCUMENTATION
} from "../../../utils";


export const buttons = [
    {
        url: PROMETHEUS,
        backgroundImg: prometheus,
        permissions: ['atom.superadmin', 'atom.service.monitoring'],
        more: {url: 'https://prometheus.io/', color: '#fff'}
    },
    {
        url: JUPYTER_HUB,
        backgroundImg: jupyter,
        permissions: ['atom.superadmin', 'atom.service.jupyterhub'],
        more: {url: 'https://jupyter.org/hub', color: '#767474'}
    },
    {
        url: JUPYTER_HUB_SECURE,
        backgroundImg: jupyter_secure,
        permissions: ['atom.superadmin', 'atom.service.jupyterhub-secure'],
        more: {url: 'https://jupyter.org/hub', color: '#767474'}
    },
    {
        url: ATOM_API_DOCUMENTATION,
        backgroundImg: fast_api_docs,
        permissions: ['atom.superadmin', 'atom.admin', 'atom.service.docs']
    },
    {
        url: HARMONIZATION_API,
        backgroundImg: data_harmonization,
        permissions: ['atom.superadmin', 'atom.admin', 'atom.service.harmonization']
    },
    {
        url: SMT,
        backgroundImg: smart_mapping,
        permissions: ['atom.superadmin', 'atom.admin', 'atom.service.smt']
    },

    {
        url: CEPH,
        backgroundImg: ceph,
        permissions: ['atom.superadmin', 'atom.service.monitoring'],
        more: {url: 'https://docs.ceph.com/en/latest/', color: '#000'}
    },
    {
        url: GRAFANA,
        backgroundImg: grafana,
        permissions: ['atom.superadmin', 'atom.service.monitoring'],
        more: {url: 'https://grafana.com/', color: '#fff'}
    }
]

export const innerButtons = [
    {
        url: GITHUB,
        backgroundImg: git,
        permissions: ['atom.superadmin', 'atom.admin', 'atom.ui.developer']
    },
    {
        url: MANAGEMENT,
        backgroundImg: management,
        permissions: ['atom.superadmin', 'atom.admin', 'atom.ui.management-ui']
    },
    {
        url: GCP,
        backgroundImg: google,
        permissions: ['atom.superadmin', 'atom.admin', 'atom.ui.developer']
    },

    {
        url: ADMIN_UI,
        backgroundImg: admin_ui,
        permissions: ['atom.superadmin', 'atom.admin', 'atom.ui.admin-ui']
    }
]

const specButtons = {
    DOCS: {
        url: ATOM_DOCUMENTATION,
        backgroundImg: read_the_docs,
        permissions: ['atom.superadmin', 'atom.admin', 'atom.ui.developer']
    },
    LIB_DOCS: {
        url: ATOM_LIB_DOCUMENTATION,
        backgroundImg: atom_library,
        permissions: ['atom.superadmin', 'atom.admin', 'atom.service.atom-lib-docs']
    }
};


if (!API.includes('dev') && !API.includes('stg')) {
    buttons.splice(6, 0, specButtons.LIB_DOCS);
    buttons.splice(7, 0, specButtons.DOCS);
}
