import {useState} from "react";
import {TextField} from "@mui/material";
import {reportIssue} from "../../../api";

const Help = ({t, setMessage, setOpen, setSeverity}) => {
        const [message, setIssueMessage] = useState('')
        return (
            <div className='help-container'>
                <TextField
                    id="outlined-multiline-static"
                    label={t('connect')}
                    multiline
                    fullWidth
                    rows={10}
                    onChange={e => {
                        if (e.target.value.length <= 500) {
                            setIssueMessage(e.target.value)
                        }
                    }}
                    value={message}
                    style={{paddingTop: '10px'}}
                    placeholder={t('issue')}/>
                <p style={{textAlign: 'right'}}>{message.length} of 500 <span
                    style={{fontWeight: 'bold'}}>{t('max_chars')}</span>
                </p>
                <button className='user-settings-btn' style={{width: '60%', marginLeft: '20%'}} onClick={_ => {
                    reportIssue(localStorage.getItem('token'), message).then(_ => {
                            setMessage(t('messages.success.report'))
                            setSeverity('success')
                            setOpen(true)
                            setIssueMessage('')
                        }
                    ).catch(err => {
                        setMessage(err.message)
                        setSeverity('error')
                        setOpen(true)
                    })
                }
                }>{t('send_msg')}</button>
            </div>
        )
    }

export default Help