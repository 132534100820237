const ModifyAddPermissions = ({permissions, searchParam}) => {
        return (permissions.filter((e) => e.perm.toLowerCase().includes(searchParam.toLowerCase())).map((e) => {
                e.checked = false
                return (
                    <div>
                        <input onChange={() => {
                            e.checked = !e.checked
                        }} type='checkbox'/>
                        {e.perm}
                    </div>)
            }
        ))
    }

export default ModifyAddPermissions