import {useState} from "react";
import {updatePreferences} from "../../../api";
import {Checkbox, FormControlLabel, FormGroup, Typography} from "@mui/material";

const NotificationSettings = ({user, setMessage, setSeverity, setOpen, t}) => {

    const [selectedPreferences, setSelectedPreferences] = useState(user.notification_settings ? user.notification_settings : [])
    const notificationPreferences = [
        'harmonization',
        'smt_follow_up',
        'smt_daily',
        'smt_weekly',
        'jupyterhub',
        'atom_lib',
    ]
    const setNotificationSettings = (notification_settings) => {
        const data = {notification_settings}
        const token = localStorage.getItem('token');
        updatePreferences(token, data)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                setMessage(err.message)
                setSeverity('error')
                setOpen(true)
            })

    }

    return (
        <div>
            <p>{t('notifications.header')}</p>
            <FormGroup className='pref'>
                {
                    notificationPreferences.map(el => {
                        return (
                            <div className='pref-element'>
                                <FormControlLabel control={<Checkbox/>}

                                                  checked={selectedPreferences.includes(el)}
                                                  label={<Typography variant="caption"
                                                                     color="black">{t(`notifications.${el}`)}</Typography>}
                                                  onChange={e => {
                                                      if (e.target.checked) {
                                                          setSelectedPreferences([...selectedPreferences, el])
                                                      } else {
                                                          setSelectedPreferences(selectedPreferences.filter(p => p !== el))
                                                      }
                                                  }}/>
                            </div>
                        )
                    })
                }
                <button
                    className='user-settings-btn'
                    onClick={_ => setNotificationSettings(selectedPreferences)}
                    style={{marginTop: '10px', width: '70%'}}>{t('notifications.btn')}
                </button>
            </FormGroup>
        </div>
    )
}

export default NotificationSettings