import {checkVersion} from "./helpers";

export const API = (process.env.REACT_APP_API.endsWith('/')) ? process.env.REACT_APP_API: process.env.REACT_APP_API + '/';
export const UI = (process.env.REACT_APP_UI_URL.endsWith('/') ? process.env.REACT_APP_UI_URL: process.env.REACT_APP_UI_URL + '/');

export const API_V1 = 'api/v1/'

const VERSION = checkVersion(API);

export const GET_USER = API + API_V1 + 'atom/user/token/validate';
export const GET_USER_DATA = API + API_V1 + 'atom/user'
export const GET_USERS = API + API_V1 + 'atom/admin/users';
export const GET_PERMISSIONS = API + API_V1 + 'atom/system/permissions';
export const ATOM_ADMIN_USER = API + API_V1 + 'atom/admin/user/';
export const PROMETHEUS = `https://prometheus.atom${VERSION}.fissionsw.com/`;
export const GRAFANA = `https://grafana.atom${VERSION}.fissionsw.com/`;
export const SMT = `https://smt.atom${VERSION}.fissionsw.com/`;
export const CEPH = `https://ceph.atom-${VERSION}.fissionsw.com/`;
export const GITHUB = 'https://github.com/orgs/FissionConsulting/repositories';
export const GCP = `https://console.cloud.google.com/home/dashboard?project=fission-atom${VERSION}`;
export const ADMIN_UI = `https://atom${VERSION}.fissionsw.com/admin`;
export const MANAGEMENT = `https://atom${VERSION}.fissionsw.com/management_ui`;
export const JUPYTER_HUB = `https://jupyter.atom${VERSION}.fissionsw.com/hub/login`;
export const JUPYTER_HUB_SECURE = `https://jupyter-secure.atom${VERSION}.fissionsw.com/hub/login`;
export const HARMONIZATION_API = `https://harmonization.atom${VERSION}.fissionsw.com`;
export const ATOM_LIB_DOCUMENTATION = `https://lib-docs.atom.fissionsw.com/`;
export const ATOM_DOCUMENTATION = `https://docs.atom.fissionsw.com/`;
export const ATOM_API_DOCUMENTATION = `https://api.atom${VERSION}.fissionsw.com/`;
export const USER_PREFERENCES = API + API_V1 + 'atom/user/preferences';
export const USER_IMAGE = API + API_V1 + 'atom/user/image';
export const REPORT_ISSUE = API + API_V1 + 'atom/user/report';
export const DOMAINS = API + API_V1 + 'atom/domains';
export  const DOMAIN_USERS = DOMAINS + '/users'

