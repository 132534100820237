import './userSettings.css';
import {useTranslation} from "react-i18next";
import {useState} from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import UserSettings from "./UserSettingsMenu";
import AccountPreferences from "./AccountPreferences";
import NotificationSettings from "./NotificationSettings";
import Language from "./Language";
import Help from "./Help";
import UserProfile from "./UserProfile";
import IconButton from "@mui/material/IconButton";

const UserSettingsMain = ({
                              setShowSettings,
                              user,
                              setIsActive,
                              setAvatarProcessing,
                              avatarProcessing,
                              userAvatar,
                              setOpen,
                              setMessage,
                              setSeverity
                          }) => {
    const {t, i18n} = useTranslation();
    const [option, setOption] = useState('User Settings')

    return (
        <div className='user-settings'>
            <div className='user-settings-header'>
                <IconButton className='elem-arrow-img back-arrow' onClick={_ => {
                    // eslint-disable-next-line no-unused-expressions
                    option === 'User Settings' ? (setIsActive(false), setShowSettings(false)) : (setOption('User Settings'));
                }}>
                    <ArrowBackIosNewIcon fontSize='large'/>
                </IconButton>

                <UserProfile user={user} userAvatar={userAvatar} setAvatarProcessing={setAvatarProcessing}
                             avatarProcessing={avatarProcessing}/>

            </div>

            <div className='user-settings-options'>
                {option === 'User Settings' && (
                    <UserSettings setOption={setOption} t={t}/>)}

                {option === 'account' && (
                    <AccountPreferences user={user} setOpen={setOpen} setMessage={setMessage} setSeverity={setSeverity}
                                        t={t}/>)}

                {option === 'notification' && (
                    <NotificationSettings setSeverity={setSeverity} user={user} setOpen={setOpen}
                                          setMessage={setMessage} t={t}/>)}

                {option === 'language' && (
                    <Language setSeverity={setSeverity} t={t} setMessage={setMessage} setOpen={setOpen} i18n={i18n}/>)}

                {option === 'help' && (
                    <Help t={t} setMessage={setMessage} setOpen={setOpen} setSeverity={setSeverity}/>)}
            </div>
        </div>
    )
}

export default UserSettingsMain;