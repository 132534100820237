import './home.css';
import {loader, background1, background2, background3, background_circle} from "./assets";
import {useState, useEffect, useRef} from "react";
import {getUser, getUserData} from "../../api";
import {redirectToAuth, isRedirect} from "../../utils";
import {processToken} from "../../utils";
import {UI} from "../../utils";
import UserSettings from "./UserSettings/UserSettings";
import {useTranslation} from "react-i18next";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {AvatarProcess} from "./AvatarProcessing/AvatarProcessing";
import {Buttons} from "./Buttons/Buttons";
import SettingsIcon from '@mui/icons-material/Settings';


const Home = () => {
    const {t, i18n} = useTranslation();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const [showSettings, setShowSettings] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [user, setUser] = useState();
    const [avatarProcessing, setAvatarProcessing] = useState(false);
    const [userAvatar, setUserAvatar] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('error');

    const subscription = useRef(true);
    useEffect(() => {
        const jwt = processToken();
        const localStorageToken = localStorage.getItem("token");
        const redirectUrl = isRedirect();

        async function getUserPermissions(token) {
            const user = await getUserData(token)
            setUser(user.data);
            setPermissions(user.data.permissions)
            i18n.changeLanguage(user.data.language)
            user.data?.image_link && setUserAvatar(user.data.image_link)
        }

        if (!jwt && !localStorageToken) {
            redirectToAuth()
        } else if (jwt) {
            getUser(jwt)
                .then(_res => {
                    if (redirectUrl) {
                        window.location = `${redirectUrl}?token=${jwt}`;
                    } else {
                        window.location = UI;
                        getUserPermissions(jwt)
                    }
                })
                .catch(_err => {
                    redirectToAuth()
                })
        } else if (localStorageToken) {
            getUser(localStorageToken)
                .then(_ => {
                    if (redirectUrl) {
                        window.location = `${redirectUrl}?token=${localStorageToken}`;
                    } else {
                        setIsAuthorized(true);
                        getUserPermissions(localStorageToken)
                        setIsLoading(false);
                    }
                })
                .catch(_err => redirectToAuth())
        }

        return () => subscription.current = false;
    }, [])

    return (
        <>
            {
                isLoading ?
                    (
                        <div style={{position: 'absolute', top: '40vh', left: 'calc((100vw - 17.44vh) / 2)'}}>
                            <img className='loader' src={loader}/>
                        </div>
                    ) : (
                        <div className='home-page'>

                            <img className="home-page-background background1" src={background1}/>
                            <img className="home-page-background background2" src={background2}/>
                            <img className="home-page-background background3" src={background3}/>


                            <Snackbar
                                open={open}
                                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                                autoHideDuration={6000}
                                onClose={_ => setOpen(false)}
                            >
                                <Alert severity={severity}>{message}</Alert>
                            </Snackbar>

                            <AvatarProcess setSeverity={setSeverity} setAvatarProcessing={setAvatarProcessing}
                                           setOpen={setOpen} setMessage={setMessage} setUserAvatar={setUserAvatar}
                                           avatarProcessing={avatarProcessing}/>

                            <div className='logout'>
                                <SettingsIcon className={isActive ? 'cogwheel cogwheel-active' : 'cogwheel'} onClick={_ => {
                                    setShowSettings(!showSettings)
                                    setIsActive(!isActive)
                                }}/>
                            </div>

                            {showSettings &&
                                <UserSettings
                                    setShowSettings={setShowSettings}
                                    user={user}
                                    setIsActive={setIsActive}
                                    setAvatarProcessing={setAvatarProcessing}
                                    avatarProcessing={avatarProcessing}
                                    userAvatar={userAvatar}
                                    setMessage={setMessage}
                                    setOpen={setOpen}
                                    setSeverity={setSeverity}
                                />}
                            <div className='background-circle'>
                                <img className='background-circle-img' src={background_circle}/>
                                <Buttons t={t} permissions={permissions}/>
                            </div>
                        </div>
                    )
            }
        </>
    )
}

export default Home;