import {Button, Modal} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    useGridApiContext
} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {getDomains, putDomains} from "../../../api/requests";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const ManageDomainsModal = ({modalOpen, setModalOpen, setMessage, setOpen, setSeverity}) => {
    const columns = [{field: 'domain', headerName: 'Domain', editable: true, flex: 1}]
    const [domains, setDomains] = useState()
    const processRowUpdate = (newValue) => {
        setDomains(domains.map(el => el.id === newValue.id ? newValue : el))
        return newValue
    }
    useEffect(() => {

        const localStorageToken = localStorage.getItem("token");

        async function getAllDomains(token) {
            const allDomains = await getDomains(token)
            setDomains(allDomains.data.domains.map((el, i) => {
                return {id: i, domain: el}
            }))
        }

        getAllDomains(localStorageToken)
    }, [modalOpen])
    const CustomToolbar = (props) => {
        const apiRef = useGridApiContext();
        return (
            <GridToolbarContainer {...props}>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
                <Button endIcon={<AddIcon/>} onClick={_ => setDomains([...domains, {id: domains.length}])}>Add New
                    Row</Button>
                <Button endIcon={<RemoveIcon/>}
                        onClick={_ => {
                            setDomains(domains.filter(el => !apiRef.current.getSelectedRows().has(el.id)).map((el, i) => {
                                return {...el, id: i}
                            }))
                            apiRef.current.setRowSelectionModel([])
                        }}>Remove Rows</Button>
            </GridToolbarContainer>)
    }

    return (<Modal open={modalOpen} onClose={_ => setModalOpen(false)}>
        <div className='modal-container'>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <h1>Manage Domains</h1>
                <IconButton onClick={_ => setModalOpen(false)}><Close/></IconButton>
            </div>
            <hr/>
            <DataGrid columns={columns} rows={domains} hideFooter checkboxSelection
                      processRowUpdate={processRowUpdate} onProcessRowUpdateError={e => console.log(e)}
                      slots={{toolbar: CustomToolbar}} disableRowSelectionOnClick/>
            <hr/>
            <Button onClick={async _ => {
                await putDomains(localStorage.getItem('token'), domains.map(el => el.domain)).then(_ => {
                    setMessage('Domains updated successfully')
                    setOpen(true)
                    setSeverity('success')
                })
                setModalOpen(false)
            }}>Save</Button>
        </div>
    </Modal>)
}

export default ManageDomainsModal