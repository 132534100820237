import {buttons, innerButtons} from "./buttonLinks";
import './buttons.css'
const onClickHandler = (url, token = true) => {
    const query = token ? `?token=${localStorage.getItem("token")}` : ''
    window.location = `${url}${query}`
}
export const Buttons = ({permissions, t}) => {
    let newButtons = buttons.filter((e) => permissions.filter((p) => e.permissions.includes(p)).length > 0)
    let newInnerButtons = innerButtons.filter((e) => permissions.filter((p) => e.permissions.includes(p)).length > 0)

    return (
        <>
            <div className='outer-circle-buttons'>
                {
                    newButtons.map((el, i) =>
                        <div className='block'
                             style={{transform: `rotate(${i * 360 / newButtons.length + 90}deg)`}}>
                            <button
                                onClick={(_) => {
                                    onClickHandler(el.url)
                                }}
                                className="square"
                                style={{
                                    transform: `rotate(${-i * 360 / newButtons.length - 90}deg)`,
                                }}
                            >
                                <img className='outer-btn' src={el.backgroundImg} alt="background"/>
                                <a href={el.more?.url} className='learn-more' onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                   style={{color: el.more?.color, borderColor: el.more?.color}}
                                   hidden={!el.more}>{t('learn')}
                                </a>
                            </button>

                        </div>)
                }
            </div>
            <div className='inner-circle-buttons'>
                {
                    newInnerButtons.map((el, i) =>
                        <div className='inner-block'
                             style={{transform: `rotate(${i * 360 / newInnerButtons.length + 90}deg)`}}>
                            <button className="inner-square"
                                    onClick={(_) => onClickHandler(el.url, false)}
                                    style={{transform: `rotate(${-i * 360 / newInnerButtons.length - 90}deg)`}}>
                                <img className='inn-btn' src={el.backgroundImg} alt="background"/>
                            </button>
                        </div>)
                }
            </div>
        </>
    )

}