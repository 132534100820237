import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {redirectToAuth} from "../../../utils";
import LogoutIcon from "@mui/icons-material/Logout";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import TuneIcon from "@mui/icons-material/Tune";
import TranslateIcon from "@mui/icons-material/Translate";
import HelpIcon from "@mui/icons-material/Help";

const buttons = [
    {
        name: 'account',
        img: _ => {
            return <PermIdentityIcon fontSize='large' className='option-icon'/>
        }
    },
    {
        name: 'notification',
        img: _ => {
            return <TuneIcon className='option-icon' fontSize='large'/>
        }
    },
    {
        name: 'language',
        img: _ => {
            return <TranslateIcon className='option-icon' fontSize='large'/>
        }
    },
    {
        name: 'help',
        img: _ => {
            return <HelpIcon className='option-icon' fontSize='large'/>
        }
    }
]
const UserSettings = ({setOption, t}) => {
    return (
        <>
            <ul className='li-container'>
                {
                    buttons.map(btn => {
                        return (
                            <li className='user-settings-elem' onClick={(_) => setOption(btn.name)}>
                                {btn.img()}
                                <p className='option'>{t(btn.name)}</p>
                                <ArrowForwardIosIcon className='arrow-icon elem-arrow-img'/>
                            </li>
                        )
                    })
                }

                <li className='user-settings-elem' onClick={(_) => {
                    localStorage.removeItem('token')
                    redirectToAuth()
                }}>
                    <LogoutIcon fontSize='large' className='option-icon'/>
                    <p className='option'>{t('logout')}</p>
                    <ArrowForwardIosIcon className='arrow-icon elem-arrow-img'/>
                </li>
            </ul>
        </>
    )
}

export default UserSettings