import {useState} from "react";
import {Button} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ModifyAddPermissions from "./ModifyAdd";
import './Modal.css'
import {Tooltip} from "@mui/material";


const ModifyUserModal = ({users, checkedUsersId, permissions, setModifyState, modifyState, t}) => {
        const [searchParam, setSearchParam] = useState('');
        const ModifyModifyPermissions = () => {
            const userList = users.filter(user => checkedUsersId.includes(user.id))
            return (permissions.filter((e) => e.perm.toLowerCase().includes(searchParam.toLowerCase())).map((e) => {

                    if (userList.length === 1 || (userList.length > 1 && userList.every((val, _, arr) => val.col4 === arr[0].col4))) {
                        e.defaultChecked = userList[0].col4.split(', ').includes(e.perm)
                        e.checked = e.defaultChecked
                    } else {
                        e.defaultChecked = false
                        e.checked = false
                    }

                    return (
                        <div>
                            <input defaultChecked={e.defaultChecked} onChange={() => {
                                e.checked = !e.checked
                                e.defaultChecked = e.checked
                            }} type='checkbox'/>
                            {e.perm}
                        </div>)
                }
            ))
        }
        const ModifyRemovePermissions = () => {
            const userList = users.filter(user => checkedUsersId.includes(user.id))
            const perms = userList.map(e => e.col4.split(', '))
            const union = [...new Set(perms.flat(2))]
            return (permissions.filter((e) => e.perm.toLowerCase().includes(searchParam.toLowerCase())).map((e) => {
                    e.checked = true
                    if (union.includes(e.perm)) {
                        return (
                            <div>
                                <input defaultChecked={true} onChange={() => {
                                    e.checked = !e.checked
                                    e.defaultChecked = e.checked
                                }} type='checkbox'/>
                                {e.perm}
                            </div>)
                    }
                }
            ))
        }

        return (
            <div className='form-header'>
                <div className='options-container'>
                    <Button
                        onClick={_ => setModifyState('Modify')}
                        endIcon={<ModeEditIcon/>}
                        variant={modifyState === 'Modify' ? 'contained' : 'outlined'}
                    >
                        {t('modify_permissions')}
                    </Button>
                    <Button
                        onClick={_ => setModifyState('Add')}
                        endIcon={<AddIcon/>}
                        variant={modifyState === 'Add' ? 'contained' : 'outlined'}
                    >
                        {t('add_permissions')}
                    </Button>
                    <Button
                        onClick={_ => setModifyState('Remove')}
                        endIcon={<RemoveIcon/>}
                        variant={modifyState === 'Remove' ? 'contained' : 'outlined'}
                    >
                        {t('remove_permissions')}
                    </Button>
                </div>
                <hr/>
                <p className='form-p'>{t('users_being_modified')}:</p>
                <ul className='form-header-user-list' style={{maxHeight: '10vh'}}>
                    {users.filter(user => checkedUsersId.includes(user.id)).map(e => <Tooltip title={e.col4}><li>{e.col1}</li></Tooltip>)}
                </ul>
                <div className='form-permissions'>
                    <div>{t("select_permissions")}</div>
                    <div className='form-input-btn-section'>
                        <input value={searchParam} onChange={(e) => setSearchParam(e.target.value)}
                               className='form-search' type='text'
                               placeholder={t("search")}/>
                        <button onClick={_ => setSearchParam('')} className='form-cancel-btn'>{t("cancel")}</button>
                    </div>

                    <hr/>

                    <div className='form-permissions-list'>

                        {modifyState === 'Modify' && <ModifyModifyPermissions/>}

                        {modifyState === 'Add' && <ModifyAddPermissions permissions={permissions} searchParam={searchParam}/>}

                        {modifyState === 'Remove' && <ModifyRemovePermissions/>}

                    </div>
                </div>
            </div>
        )
    }

export default ModifyUserModal